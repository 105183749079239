import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { ContentBannerPrimitive } from './content-banner.primitive';
import {
  contentBannerDuration,
  contentBannerTransitionName,
} from './content-banner.styles';
import type { ContentBannerProps } from './content-banner.types';

export const ContentBanner = React.forwardRef<
  HTMLDivElement,
  ContentBannerProps
>(
  (
    {
      children,
      className,
      closeButtonProps,
      display = 'fixed',
      handleOnClose,
      noPadding,
      onExited,
      show = true,
      showCloseButton = true,
      variant = 'default',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const bannerRef = React.useRef<HTMLDivElement>(null);
    const combinedRef = React.useMemo(() => {
      if (forwardedRef) {
        return typeof forwardedRef === 'function'
          ? { current: null, set: forwardedRef }
          : forwardedRef;
      }
      return bannerRef;
    }, [forwardedRef]);
    const timeout = display === 'fixed' ? parseInt(contentBannerDuration) : 0;

    // Focus management when banner appears
    React.useEffect(() => {
      if (show && display === 'fixed') {
        bannerRef.current?.focus();
      }
    }, [show, display]);

    const contentBanner = (
      <ContentBannerPrimitive
        closeButtonProps={closeButtonProps}
        display={display}
        handleOnClose={handleOnClose}
        noPadding={noPadding}
        ref={combinedRef}
        showCloseButton={showCloseButton}
        tabIndex={-1}
        variant={variant}
        {...props}
      >
        {children}
      </ContentBannerPrimitive>
    );

    const ContentBannerTransition = (
      <CSSTransition
        appear={show}
        className={className}
        classNames={contentBannerTransitionName}
        in={show}
        key="contentbanner"
        mountOnEnter
        onExited={onExited}
        timeout={timeout}
        unmountOnExit
      >
        {contentBanner}
      </CSSTransition>
    );

    if (display === 'fixed') {
      return <Portal>{ContentBannerTransition}</Portal>;
    }

    return ContentBannerTransition;
  }
);

ContentBanner.displayName = 'ContentBanner';
